import makeEnvVarAccessors from "@connectedliving/common/lib/utilities/makeEnvironmentVariableAccessors";

const { requireEnvVar } = makeEnvVarAccessors({
  environmentNameVariable: "REACT_APP_CONNECTED_LIVING_ENVIRONMENT",
});

export default function environment() {
  return {
    requireEnvVar,
    supportUserId: () => requireEnvVar("REACT_APP_SUPPORT_USER_ID"),
    webAppDomain: () => requireEnvVar("REACT_APP_WEB_APP_DOMAIN"),
  };
}
