import {
  BooleanField,
  Datagrid,
  List,
  TextField,
  TextInput,
} from "react-admin";
import UtmParamsField from "../../fields/UtmParamsField";

const userProfileFilters = [
  <TextInput key="id" label="ID" source="id" alwaysOn />,
  <TextInput key="firstName" label="First Name" source="firstName" alwaysOn />,
  <TextInput key="lastName" label="Last Name" source="lastName" alwaysOn />,
];

export const UserProfilesList = (props: any) => {
  return (
    <List {...props} filters={userProfileFilters}>
      <Datagrid rowClick="show">
        <TextField source="id" />
        <TextField source="firstName" emptyText="-" />
        <TextField source="lastName" emptyText="-" />
        <TextField
          source="language"
          label="Translation Language"
          emptyText="-"
        />

        <UtmParamsField source="utmParams" label="UTM Params" emptyText="-" />

        <BooleanField source="isEmployee" label="Employee?" />
      </Datagrid>
    </List>
  );
};
