export function channelsListPageUrl({ teamId }: { teamId: string }): string {
  return `/teams/${teamId}/channels`;
}
export function teamAdminPageUrl({ teamId }: { teamId: string }): string {
  return `/teams/${teamId}/admin`;
}
export function editTeamPageUrl({ teamId }: { teamId: string }): string {
  return `/teams/${teamId}/edit`;
}

export function teamUrl({ teamId }: { teamId: string }): string {
  return `/teams/${teamId}`;
}
export type TeamUrlParams = Parameters<typeof teamUrl>[0];

export function newTeamChannelPageUrl({ teamId }: TeamUrlParams): string {
  return `${teamUrl({ teamId })}/team-channels/new`;
}

export function teamChannelDetailPageUrl({
  teamId,
  teamChannelId,
}: {
  teamId: string;
  teamChannelId: string;
}): string {
  return `${teamUrl({ teamId })}/team-channels/${teamChannelId}/detail`;
}

export function directMessageChannelDetailPageUrl({
  teamId,
  authUserId,
  otherUserId,
}: {
  teamId: string;
  authUserId: string;
  otherUserId: string;
}): string {
  return `${teamUrl({
    teamId,
  })}/users/${authUserId}/direct-message-channels/${otherUserId}/detail`;
}

export function discoverChannelsPageUrl({
  teamId,
}: {
  teamId: string;
}): string {
  return `${teamUrl({ teamId })}/channels/discover`;
}

export function editMyUserProfilePageUrl({
  teamId,
}: {
  teamId: string;
}): string {
  return `${teamUrl({ teamId })}/my-user-profile/edit`;
}

export function userSettingsPageUrl({ teamId }: { teamId: string }): string {
  return `${teamUrl({ teamId })}/settings`;
}

export function viewUserProfilePageUrl({
  teamId,
  userProfileId,
}: TeamUrlParams & {
  userProfileId: string;
}): string {
  return `${teamUrl({ teamId })}/user-profiles/${userProfileId}`;
}
export type ViewUserProfilePageUrlParams = Parameters<
  typeof viewUserProfilePageUrl
>[0];

export function userProfilesListPageUrl({
  teamId,
}: {
  teamId: string;
}): string {
  return `${teamUrl({ teamId })}/user-profiles`;
}

export function directMessageChannelMessagesPageUrl({
  teamId,
  authUserId,
  otherUserId,
}: {
  teamId: string;
  authUserId: string;
  otherUserId: string;
}): string {
  return `${teamUrl({
    teamId,
  })}/users/${authUserId}/direct-message-channels/${otherUserId}/messages`;
}

export function teamChannelMessagesPageUrl({
  teamId,
  teamChannelId,
}: {
  teamId: string;
  teamChannelId: string;
}): string {
  return `${teamUrl({ teamId })}/team-channels/${teamChannelId}/messages`;
}

export function closedBetaPageUrl(): string {
  return `/join-team`;
}
export function getStartedPageUrl(): string {
  return `/get-started`;
}
export function loginPageUrl(): string {
  return `/login`;
}

export function teamCreatedSuccessPageUrl({ teamId }: TeamUrlParams): string {
  return `/community-created/${teamId}`;
}

export type TeamCreatedSuccessPageUrlParams = Parameters<
  typeof teamCreatedSuccessPageUrl
>[0];

export type TeamCreatePageUrlParams = {
  placeId: string;
};

export function teamCreatePageUrl({
  placeId,
}: TeamCreatePageUrlParams): string {
  return `/create-community/${placeId}`;
}

export function languageSettingsPageUrl({ teamId }: TeamUrlParams): string {
  return `${userSettingsPageUrl({ teamId })}/language`;
}

export function pushNotificationSettingsPageUrl({
  teamId,
}: TeamUrlParams): string {
  return `${userSettingsPageUrl({ teamId })}/push-notifications`;
}

export function backofficePageUrl(): string {
  return `/backoffice`;
}

export function supportDirectMessageChannelPageUrl({
  teamId,
  supportUserId,
  otherUserId,
}: {
  teamId: string;
  supportUserId: string;
  otherUserId: string;
}): string {
  return [
    backofficePageUrl(),
    directMessageChannelMessagesPageUrl({
      teamId,
      authUserId: supportUserId,
      otherUserId,
    }),
  ].join("");
}

export type SupportDirectMessageChannelPageParams = Parameters<
  typeof supportDirectMessageChannelPageUrl
>[0];

export function checkMyAddressPageUrl(): string {
  return `/check-my-address`;
}

export function checkMyAddressResultsPageUrl({
  placeId,
}: {
  placeId: string;
}): string {
  return `${checkMyAddressPageUrl()}/results/${placeId}`;
}

export function joinTeamPageUrl({
  teamId,
  placeId,
}: {
  placeId: string;
  teamId: string;
}): string {
  return `${checkMyAddressResultsPageUrl({
    placeId,
  })}/join-community/${teamId}`;
}

export type JoinTeamPageParams = Parameters<typeof joinTeamPageUrl>[0];

export function leaveTeamPageUrl({ teamId }: { teamId: string }) {
  return `/leave-team/${teamId}`;
}
