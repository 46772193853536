import {
  Datagrid,
  DateField,
  List,
  NumberField,
  ReferenceField,
  TextField,
  TextInput,
  WrapperField,
} from "react-admin";
import UtmParamsField from "../../fields/UtmParamsField";

const teamFilters = [
  <TextInput key="id" label="ID" source="id" alwaysOn />,
  <TextInput key="name" label="Name" source="name" alwaysOn />,
];

export const TeamsList = (props: any) => {
  return (
    <List {...props} filters={teamFilters}>
      <Datagrid rowClick="show">
        <TextField source="id" />

        <TextField source="name" />

        <ReferenceField source="creatorId" reference="UserProfiles" link="show">
          <WrapperField>
            <TextField source="firstName" />
            <TextField source="lastName" sx={{ ml: 1 }} />
          </WrapperField>
        </ReferenceField>

        <DateField
          source="starterKitSentAt"
          options={{ hour: "numeric", minute: "numeric" }}
        />

        <NumberField source="numberOfApartments" label="# Apartments" />

        <NumberField source="userIds.length" label="# Users" />

        <UtmParamsField source="utmParams" label="UTM Params" />
      </Datagrid>
    </List>
  );
};
