import { Admin, DataProvider, Resource } from "react-admin";
import {
  FirebaseAuthProvider,
  FirebaseDataProvider,
  RAFirebaseOptions,
} from "react-admin-firebase";
import "./App.css";
import TeamShow from "./resources/Team/TeamShow";
import { TeamsList } from "./resources/Team/TeamsList";
import UserProfileShow from "./resources/UserProfile/UserProfileShow";
import { UserProfilesList } from "./resources/UserProfile/UserProfilesList";
import environment from "./utilities/environment";

const FirebaseConfig = {
  apiKey: environment().requireEnvVar("REACT_APP_FIREBASE_API_KEY"),
  authDomain: environment().requireEnvVar("REACT_APP_FIREBASE_AUTH_DOMAIN"),
  projectId: environment().requireEnvVar("REACT_APP_FIREBASE_PROJECT_ID"),
  storageBucket: environment().requireEnvVar(
    "REACT_APP_FIREBASE_STORAGE_BUCKET",
  ),
  appId: environment().requireEnvVar("REACT_APP_FIREBASE_APP_ID"),
};

const options: RAFirebaseOptions = {
  logging: true,
  persistence: "local",
};
const dataProvider = FirebaseDataProvider(
  FirebaseConfig,
  options,
) as unknown as DataProvider;
const authProvider = FirebaseAuthProvider(FirebaseConfig, options);

function App() {
  return (
    <Admin dataProvider={dataProvider} authProvider={authProvider}>
      <Resource name="Teams" list={TeamsList} show={TeamShow} />
      <Resource
        options={{ label: "User Profiles" }}
        name="UserProfiles"
        list={UserProfilesList}
        show={UserProfileShow}
      />
    </Admin>
  );
}

export default App;
