import { Typography } from "@mui/material";
import React from "react";
import {
  BooleanField,
  Datagrid,
  DateField,
  ImageField,
  NumberField,
  Pagination,
  ReferenceArrayField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  Tab,
  TabbedShowLayout,
  TextField,
  WithRecord,
  WrapperField,
} from "react-admin";
import UtmParamsField from "../../fields/UtmParamsField";

export type UserProfileShowProps = {};

/*
  createdTeamIds: string[];
  */
const UserProfileShow: React.FC<UserProfileShowProps> = (props) => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <Typography variant="h5">
          UserProfile:{" "}
          <WithRecord
            render={(record) => (
              <>
                {record.firstName} {record.lastName}
              </>
            )}
          />
        </Typography>

        <TextField source="id" />
        <TextField source="firstName" emptyText="-" />
        <TextField source="lastName" emptyText="-" />
        <TextField
          source="language"
          label="Translation Language"
          emptyText="-"
        />
        <ImageField source="imageUrl" label="Profile Image" emptyText="-" />

        <UtmParamsField source="utmParams" label="UTM Params" emptyText="-" />

        <BooleanField source="isEmployee" emptyText="-" />

        <TabbedShowLayout>
          <Tab label="Teams">
            <ReferenceArrayField
              label="Teams"
              reference="Teams"
              source="teamIds"
              perPage={10}
              pagination={<Pagination />}
            >
              <Datagrid rowClick="show">
                <TextField source="id" />
                <TextField source="name" />
                <ReferenceField
                  source="creatorId"
                  reference="UserProfiles"
                  link="show"
                >
                  <WrapperField>
                    <TextField source="firstName" />
                    <TextField source="lastName" sx={{ ml: 1 }} />
                  </WrapperField>
                </ReferenceField>
                <TextField source="inviteCode" />

                <DateField
                  source="starterKitSentAt"
                  options={{ hour: "numeric", minute: "numeric" }}
                />

                <NumberField source="numberOfApartments" />

                <UtmParamsField source="utmParams" label="UTM Params" />
              </Datagrid>
            </ReferenceArrayField>
          </Tab>
        </TabbedShowLayout>
      </SimpleShowLayout>
    </Show>
  );
};

export default UserProfileShow;
