import { supportDirectMessageChannelPageUrl } from "@connectedliving/common/lib/utilities/urlBuilders";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import { Stack, Typography } from "@mui/material";
import { PropsWithChildren, ReactElement } from "react";
import {
  BooleanField,
  Button,
  Datagrid,
  DateField,
  NumberField,
  Pagination,
  ReferenceArrayField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  Tab,
  TabbedShowLayout,
  TextField,
  WithRecord,
  WrapperField,
} from "react-admin";
import environment from "src/utilities/environment";
import UtmParamsField from "../../fields/UtmParamsField";

export type TeamShowProps = {};

function SupportDmLinkField(props: {
  value: string | undefined | null | false;
  text: string | undefined | null | false;
}) {
  const { value, text } = props;

  if (!value) {
    return null;
  }

  return (
    <Button label={text || ""} href={value}>
      <ChatBubbleOutlineIcon />
    </Button>
  );
}

function TeamShow(
  props: PropsWithChildren<TeamShowProps>,
): ReactElement | null {
  return (
    <Show
      title={<WithRecord render={(record) => <>Team: {record.name}</>} />}
      {...props}
    >
      <Stack direction={{ xs: "column", sm: "row" }}>
        <SimpleShowLayout>
          <TextField source="id" />

          <TextField
            source="name"
            sx={{
              fontWeight: "bold",
            }}
          />

          <TextField
            source="inviteCode"
            sx={{
              fontWeight: "bold",
              typography: { textTransform: "uppercase" },
            }}
          />

          <DateField
            source="starterKitSentAt"
            options={{ hour: "numeric", minute: "numeric" }}
            emptyText="-"
          />

          <NumberField source="numberOfApartments" emptyText="-" />

          <NumberField source="userIds.length" label="# Users" />

          <UtmParamsField source="utmParams" label={false} />
        </SimpleShowLayout>

        <SimpleShowLayout>
          <Typography sx={{ fontWeight: "bold" }}>Creator</Typography>

          <ReferenceField
            source="creatorId"
            reference="UserProfiles"
            link="show"
            label="Name"
            emptyText="-"
          >
            <WrapperField>
              <TextField source="firstName" />
              <TextField source="lastName" sx={{ ml: 1 }} />
            </WrapperField>
          </ReferenceField>

          <div>
            <WithRecord
              render={(record) => {
                const supportUserId = environment().supportUserId();
                const supportConversationPath =
                  record.creatorId &&
                  supportDirectMessageChannelPageUrl({
                    teamId: record.id.toString(),
                    supportUserId,
                    otherUserId: record.creatorId,
                  });
                const supportConversationUrl =
                  supportConversationPath &&
                  `${environment().webAppDomain()}${supportConversationPath}`;

                return (
                  <SupportDmLinkField
                    value={supportConversationUrl}
                    text="Support DM"
                  />
                );
              }}
            />
          </div>
        </SimpleShowLayout>
      </Stack>

      <TabbedShowLayout>
        <Tab label="Members">
          <ReferenceArrayField
            label={false}
            reference="UserProfiles"
            source="userIds"
            perPage={10}
            pagination={<Pagination />}
          >
            <Datagrid rowClick="show">
              <TextField source="firstName" />
              <TextField source="lastName" />
              <BooleanField source="isEmployee" />
            </Datagrid>
          </ReferenceArrayField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
}

export default TeamShow;
