import React, { PropsWithChildren, ReactElement } from "react";
import {
  Labeled,
  TextField,
  WrapperField,
  WrapperFieldProps,
} from "react-admin";

export type UtmParamsFieldProps = Omit<WrapperFieldProps, "children">;

function UtmParamsField(
  props: PropsWithChildren<UtmParamsFieldProps>,
): ReactElement<any, any> | null {
  return (
    <WrapperField {...props}>
      <Labeled label="UTM Medium">
        <TextField source="utmParams.utm_medium" emptyText="-" />
      </Labeled>
      <Labeled label="UTM Source" sx={{ ml: 1 }}>
        <TextField source="utmParams.utm_source" emptyText="-" />
      </Labeled>
      <Labeled label="UTM Campaign" sx={{ ml: 1 }}>
        <TextField source="utmParams.utm_campaign" emptyText="-" />
      </Labeled>
      <Labeled label="UTM Term" sx={{ ml: 1 }}>
        <TextField source="utmParams.utm_term" emptyText="-" />
      </Labeled>
      <Labeled label="UTM Content" sx={{ ml: 1 }}>
        <TextField source="utmParams.utm_content" emptyText="-" />
      </Labeled>
      <Labeled label="UTM Item" sx={{ ml: 1 }}>
        <TextField source="utmParams.utm_item" emptyText="-" />
      </Labeled>
    </WrapperField>
  );
}

export default UtmParamsField;
